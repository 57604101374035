// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps, useRef, useEffect, useMemo, forwardRef } from 'react';

import { styled } from '@compiled/react';
import logger from '@atlassian/jira-common-util-logging/src/log.tsx';
import { setupConnectJs } from '@atlassian/jira-connect/src/index.tsx';
import { SkeletonGadgetContent } from '../../../../common/gadget/gadget-skeleton/index.tsx';
import { useOnGadgetRender } from '../../../../controllers/above-the-fold/main.tsx';
import { useForcedHeight } from '../../../../controllers/gadget-additional-state.tsx';
import { useGadgetRefresh, useIsConnectDisabled } from '../../../../controllers/gadget/context.tsx';
import { useIsGlobalsReady } from '../../../../controllers/is-globals-ready/index.tsx';
import { getLocalStorageHeight } from '../../../../utils/local-storage/index.tsx';
import type { TitleSetter } from '../../../../utils/use-gadget-title/index.tsx';

const GadgetContainer = forwardRef<HTMLDivElement, ComponentProps<typeof GadgetContainerComponent>>(
	(props, ref) => (
		// for monolith to identify gadget
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<GadgetContainerComponent className="connect-gadget" {...props} ref={ref} />
	),
);

export type ConnectProps = {
	isLoading: boolean;
	isInEditMode: boolean;
	dashboardId?: string;
	gadgetId: string;
	inlineHtml: string | undefined;
	setIsLoading: (arg1: boolean) => void;
	onEditModeCancel: () => void;
	onRegister: (id: string, setTitle: TitleSetter) => void;
	onUnregister: (id: string) => void;
	setTitle: TitleSetter;
};

export const Connect = ({
	dashboardId,
	gadgetId,
	inlineHtml,
	isLoading,
	isInEditMode,
	setIsLoading,
	onEditModeCancel,
	onRegister,
	onUnregister,
	setTitle,
}: ConnectProps) => {
	const divRef = useRef<HTMLDivElement>(null);
	const isGlobalsReady = useIsGlobalsReady();

	const isDisabled = useIsConnectDisabled();
	const forcedHeightFromState = useForcedHeight(gadgetId);
	const forcedHeight = useMemo(
		() => forcedHeightFromState ?? getLocalStorageHeight(dashboardId, gadgetId),
		[forcedHeightFromState, dashboardId, gadgetId],
	);
	const onGadgetRender = useOnGadgetRender(gadgetId);
	const onRefresh = useGadgetRefresh();

	const showSkeleton = isLoading && !isInEditMode;

	useEffect(() => {
		// TODO - inlineHtml missing should be a hard error not a type refinement
		if (!isGlobalsReady || inlineHtml == null) return;

		setupConnectJs()
			.then(() => {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				const slotHtml = document.createRange().createContextualFragment(inlineHtml);
				if (divRef.current) {
					divRef.current.innerHTML = '';
					divRef.current.appendChild(slotHtml);
					setIsLoading(false);
					onGadgetRender();
				}
			})
			.catch((error) => {
				logger.safeErrorWithoutCustomerData(
					'spa-apps.dashboard.gadget.connect',
					'Failed to render Connect gadget',
					error,
				);
				// TODO - where is setError here, where is reportError here?? (compare with amd gadget)
			});
	}, [isGlobalsReady, inlineHtml, onGadgetRender, setIsLoading]);

	useEffect(() => {
		onRegister(gadgetId, setTitle);
		return () => {
			onUnregister(gadgetId);
		};
	}, [gadgetId, setTitle, onRegister, onUnregister]);

	useEffect(() => {
		const target = divRef.current?.getElementsByTagName('iframe')[0];
		if (!target || !isInEditMode || isLoading) return;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.connectHost?.broadcastEvent('jira_dashboard_item_edit', {
			id: target.getAttribute('id'),
		});
	}, [isInEditMode, isLoading]);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const connectEventHandler = (event: any) => {
			const eventData = event.data;
			const target = divRef.current?.getElementsByTagName('iframe')[0];
			if (target == null || eventData == null || eventData.eid !== target.getAttribute('id')) {
				return;
			}

			if (eventData.type === 'unload') {
				onRefresh();
			} else {
				onEditModeCancel();
			}
		};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('message', connectEventHandler, false);

		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('message', connectEventHandler);
		};
	}, [dashboardId, gadgetId, inlineHtml, isGlobalsReady, onRefresh, onEditModeCancel]);

	return (
		<Container minHeight={forcedHeight}>
			<GadgetContainer
				id={`gadget-${gadgetId}`}
				data-gadget-id={gadgetId}
				data-testid="dashboard-internal-common.ui.gadget.gadget-inner.connect.connect"
				ref={divRef}
				isHidden={showSkeleton}
				isDisabled={isDisabled}
			/>
			{showSkeleton && (
				<SkeletonWrapper data-testid="dashboard-internal-common.ui.gadget.gadget-inner.connect.loading-skeleton">
					<SkeletonGadgetContent dashboardId={dashboardId} gadgetId={gadgetId} />
				</SkeletonWrapper>
			)}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const SkeletonWrapper = styled.div({
	position: 'absolute',
	left: 0,
	top: 0,
	width: '100%',
	height: '100%',
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
	background: '#ffffff',
	zIndex: 50,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Container = styled.div<{ minHeight?: number }>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minHeight: ({ minHeight }) => `${minHeight}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const GadgetContainerComponent = styled.div<{ isDisabled: boolean; isHidden: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isHidden }) => (isHidden ? 'none' : 'inherit'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	pointerEvents: ({ isDisabled }) => (isDisabled ? 'none' : 'inherit'),
});
