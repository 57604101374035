import React from 'react';
import { styled } from '@compiled/react';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { PAGE_LAYOUT_OFFSET_TOP } from '@atlassian/jira-common-constants/src/page-layout.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

export const GenericLoadingView = ({ customHeight }: { customHeight?: string }) => (
	<UFOLoadHold name="generic-loading-view">
		<Wrapper>
			<Header>
				<Title />
				<Button />
				<Button />
			</Header>
			<ContainerWrapper customHeight={customHeight} />
		</Wrapper>
	</UFOLoadHold>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	alignItems: 'flex-start',
	display: 'flex',
	flexWrap: 'wrap',
	maxWidth: '100%',
	position: 'relative',
	margin: '0px auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContainerWrapper = styled.div<{ customHeight?: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.skeleton', colors.N20),
	/* top nav + dashboard title + gridSize * 2 padding */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ customHeight }) =>
		`calc( ${
			customHeight != null
				? customHeight
				: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					`100vh - ${PAGE_LAYOUT_OFFSET_TOP} - ${gridSize * 4 + gridSize * 8}px`
		} )`,
	width: '100%',
	zIndex: 100,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Header = styled.div({
	display: 'flex',
	width: '100%',
	margin: `${token('space.300', '24px')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'div:last-child': {
		margin: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.div({
	marginRight: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.skeleton', colors.N20),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 4}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 34}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Button = styled.div({
	marginRight: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.skeleton', colors.N20),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 4}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 4}px`,
});
