import React from 'react';
import Blanket from '@atlaskit/blanket';
import { MODAL_ID } from '@atlassian/jira-dashboard-common/src/constants.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type DashboardConfigureWallboardModal from './modal/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyDashboardModal = lazy<typeof DashboardConfigureWallboardModal>(
	() => import(/* webpackChunkName: "async-dashboard-configure-wallboard-modal" */ './modal'),
);

export const ConfigureWallboardModal = ({
	onModalCloseCallback,
}: {
	onModalCloseCallback?: () => void;
}) => {
	const [isOpen, { off: close }] = useSwitchModals(MODAL_ID.CONFIGURE_WALLBOARD);

	const onModalClose = () => {
		close();
		onModalCloseCallback?.();
	};

	return isOpen ? (
		<JSErrorBoundary
			id="configure-wallboard-modal"
			packageName="dashboard"
			fallback="flag"
			teamName="endeavour"
		>
			<Placeholder name="dashboard-modal" fallback={<Blanket isTinted />}>
				<LazyDashboardModal onClose={onModalClose} />
			</Placeholder>
		</JSErrorBoundary>
	) : null;
};
